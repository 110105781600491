import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import * as moment from 'moment'
import dash from "../../images/dash.png"


const IndexPage = ({data}) => {
  let posts = data.graphAPI.blogPosts

  function slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = text.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-y-')           // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
  }

  return (
  <Layout>
    <SEO title="News" />
    <div className="container anchor">
      <div className="columns" style={{marginTop:'3rem'}}>
        <div className="column is-4-desktop">
          <span><img width="40" style={{paddingBottom: 4, marginRight: '0.5rem'}} src={dash} alt=""></img> NEWS</span>
          <div className="indented">
            <h1 className="is-size-1" style={{ marginTop: 0}}>For the latest updates</h1>
            <p>Check out the latest articles on the Sugar Land Legacy Foundation.</p>
          </div>
        </div>
        <div className="column is-8-tablet is-7-desktop is-offset-1-desktop">
          <div className="card-container">
            {posts.map(post => (
              <div className="card card-item" style={{margin: '1rem 0'}}>
                <div className="card-content">
                  <h4 style={{marginBottom: '0'}}>{post.title}</h4>
                  <p className="has-text-grey">Posted: {moment(post.publishedAt).format("MMMM Do, YYYY")}</p>
                  <div dangerouslySetInnerHTML={{__html: post.description}}></div>

                </div>
                <div className="card-footer">
                  { post.postType === "Website" ? (
                    <a href={post.websiteLink} target="_blank" rel="noopener noreferrer" className="button is-white has-text-grey">View Article</a>
                  ) : (
                    <>
                    { post.postType === "Document" ? (
                    <a href={post.document.documentFile.publicURL} target="_blank" rel="noopener noreferrer" className="button is-white has-text-grey">View Document</a>
                    ) : (
                      <>
                      { post.postType === "Article" ? (
                      <Link to={moment(post.publishedAt).format("YYYY-MM-DD")+'/'+slugify(post.title)} className="button is-white has-text-grey">View Article</Link>
                      ) : (
                        <a href="#" className="button is-white has-text-grey">...</a>
                      ) }
                      </>
                    ) }
                    </>
                  ) }

                </div>
                
              </div>
            ))}
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)}

export default IndexPage

export const indexQuery = graphql`
  query {
    graphAPI {
      blogPosts(limit: 1000, sort: "publishedAt:desc") {
        id
        title
        publishedAt
        description
        postType
        websiteLink
        document {
          url
          created_at
          updated_at
          documentFile {
            publicURL
          }
        }
      }
    }
  }
`